<template>
  <v-dialog v-model="dialog" max-width="650px" :persistent="!readonly">
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>
          {{newUser ? "Novo Usuário " : readonly ? "Usuário" : "Edição de Usuário"}}
        </v-toolbar-title>
        <v-spacer />
        <v-fade-transition>
          <v-toolbar-items v-if="readonly" class="align-center">
            <div>
              <v-btn
                small
                depressed
                class="mr-4"
                color="error darken-1"
                @click="deleteUser()">
                <v-icon small class="mr-1">mdi-delete</v-icon>
                Deletar
              </v-btn>
              <v-btn
                small
                depressed
                color="grey lighten-3 black--text"
                class="mr-4"
                @click="readonly = false">
                <v-icon small class="mr-1">mdi-pencil</v-icon>
                Editar
              </v-btn>
            </div>
            <v-btn icon class="mr-1" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-fade-transition>
      </v-toolbar>
      <v-form ref="userForm" @submit.prevent="saveUser()">
        <!--Editing or Creating-->
        <v-card-text>
          <v-text-field
            class="mb-3"
            filled
            :readonly="readonly"
            :disabled="loading"
            :background-color="readonly ? 'grey lighten-3' : ''"
            hide-details="auto"
            :rules="[$rules.required]"
            v-model="user.name"
            label="Nome *"/>
          <v-text-field
            class="mb-3"
            hide-details="auto"
            :disabled="loading"
            :background-color="readonly ? 'grey lighten-3' : ''"
            filled
            :readonly="readonly"
            :rules="[$rules.email, $rules.required]"
            v-model="user.email"
            label="E-Mail *"/>
          <v-text-field
            class="mb-3"
            v-if="newUser"
            v-model="user.password"
            hide-details="auto"
            filled
            :rules="[$rules.passwordMin]"
            label="Senha *"
            :disabled="loading"
            :append-icon="viewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="viewPassword = !viewPassword"
            :type="viewPassword ? 'text' : 'password'"/>
          <v-autocomplete
            ref="companies"
            filled
            multiple
            :disabled="loading || loadingCompanies"
            :loading="loadingCompanies"
            hide-details="auto"
            :rules="[$rules.requiredList]"
            v-model="selectedCompanies"
            :items="companies"
            item-text="name"
            return-object
            no-data-text="Nenhum cliente encontrada"
            label="Clientes *"
            class="mb-2"
            chips
            deletable-chips
            :readonly="readonly"
            @blur="updateAdminSelection()"
          >
            <template v-slot:append-item>
              <v-divider class="my-2"/>
              <div class="d-flex mx-2 align-center">
                <v-btn
                  v-if="$store.state.user.is_accountant"
                  small
                  depressed
                  color="primary"
                  @click="createCompany()">
                  <v-icon left> mdi-plus </v-icon>
                  Novo cliente
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="$refs.companies.blur()"
                  depressed
                  color="success">
                  OK
                </v-btn>
              </div>
            </template>
          </v-autocomplete>
          <template v-for="company in user.companies">
            <v-checkbox
              :key="company.id"
              :disabled="loading"
              :readonly="readonly"
              :label="`Administrador de ${company.name}`"
              hide-details="auto"
              v-model="company.is_admin"/>
          </template>
        </v-card-text>
        <v-expand-transition>
          <v-card-actions class="pb-4 pr-4 pt-0" v-if="!readonly">
            <v-spacer />
            <v-btn
              text
              v-if="!readonly"
              @click="dialog = false"
              :disabled="loading">
              Cancelar
            </v-btn>
            <v-btn color="success" type="submit" :loading="loading">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-expand-transition>
      </v-form>
    </v-card>
    <template>
      <ViewEditAndCreateCompany
        ref="viewCompany"
        @created="
          loadCompanies();
          selectedCompanies.push($event);
          updateAdminSelection();
        "
      />
    </template>
  </v-dialog>
</template>

<script>
import ViewEditAndCreateCompany from "@/components/ViewEditAndCreateCompany.vue";

export default {
  name: "ViewEditCreateUserDialog",
  components: {
    ViewEditAndCreateCompany,
  },
  data: () => ({
    dialog: false,
    loading: false,
    newUser: false,
    viewPassword: false,
    loadingCompanies: false,
    readonly: true,
    companies: [],
    selectedCompanies: [],
    user: {
      id: undefined,
      name: "",
      email: "",
      admin: [],
      password: "",
      companies: [],
    },
  }),

  methods: {
    open(user) {
      if (user) {
        this.user = JSON.parse(JSON.stringify(user));
        this.selectedCompanies = this.user.companies;
      } else {
        this.user.id = undefined;
        this.newUser = true;
        this.readonly = false;
        this.dialog = true;
      }
      this.dialog = true;
      this.loadCompanies();
    },
    openWithCompany(company) {
      this.user = {
        admin: [],
        companies: [],
      };
      this.newUser = true;
      this.readonly = false;
      this.selectedCompanies = [company];
      this.updateAdminSelection();
      this.dialog = true;
      this.loadCompanies();
    },
    createCompany() {
      this.$refs.viewCompany.open();
    },
    loadCompanies() {
      this.loadingCompanies = true;
      this.$axios
        .get("/companies/available")
        .then((response) => {
          this.companies = response.data.all;
        })
        .catch((error) => {
          this.$showMessage("error", "Não foi possivel carregar as empresas");
        })
        .finally(() => {
          this.loadingCompanies = false;
        });
    },
    saveUser() {
      if (!this.$refs.userForm.validate()) {
        this.$showMessage("warning", "Preencha todos os campos corretamente");
      } else {
        this.loading = true;
        let request;
        if (this.user.id !== undefined) {
          request = this.$axios.put(`/user/${this.user.id}`, this.user);
        } else {
          request = this.$axios.post(`/user`, this.user);
        }
        request
          .then(() => {
            this.$showMessage("success", "Usuário Salvo");
            this.dialog = false;
            this.$emit("updated");
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.$showMessage("error", "Acesso a empresa não autorizada");
                  return;
                case 404:
                  this.$showMessage("error", "Usuário não encontrado");
                  return;
                case 409:
                  this.$showMessage("error", "Esse e-mail já está em uso");
                  return;
              }
            }
            this.$showMessage(
              "error",
              "Erro inesperado na alteração dos dados"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    deleteUser() {
      this.$confirmDialog(
        "Você deseja deletar esta Usuário?",
        "O Usuário será apagado permanentemente.",
        () =>
          new Promise((resolve, reject) => {
            this.$axios
              .delete(`/user/${this.user.id}`)
              .then(() => {
                resolve();
                this.$showMessage("success", "Usuário deletado");
                this.$emit("updated");
                this.dialog = false;
              })
              .catch((error) => {
                reject();
                switch (error.response.status) {
                  case 401:
                    this.$showMessage("error", "Não autorizado");
                    break;
                  case 404:
                    this.$showMessage("error", "Usuário não encontrado");
                    break;
                  default:
                    this.$showMessage(
                      "error",
                      "Erro inesperado ao deletar usuário"
                    );
                    break;
                }
              });
          })
      );
    },
    updateAdminSelection() {
      const previous = Object.assign(
        {},
        ...this.user.companies.map((company) => ({
          [company.id]: company.is_admin,
        }))
      );
      this.user.companies = this.selectedCompanies.map((company) => ({
        id: company.id,
        is_admin: previous[company.id] || false,
        name: company.name,
      }));
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.newUser = false;
        this.$refs.userForm.resetValidation();
        this.readonly = true;
      }
    },
  },
};
</script>
